<template>
  <section id="about">
    <div class="row q-mx-auto q-pa-md justify-between items-start">
      <div class="col q-pr-md" id="about">
        <h1>Historiana Labs</h1>
        <h5>
          Historiana Labs is Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</h5>
        <p>&nbsp;</p>
        <hr />
        <h5>Join Historiana Labs</h5>
        <p>
          Are you interested in the development of Historiana? Would you like to
          stay up to date about new content and features? Or would you like to
          contribute to the continuous development of Historiana? Please join
          the
          <a
            href="https://www.facebook.com/groups/historiana/permalink/1359586110726889/"
            target="_blank"
          >Historiana Facebook group</a>. Contributions to Historiana Labs are welcomed, please contact us at
          <a
            ref="mailto:labs@historiana.eu"
          >labs@historiana.eu</a>. History
          educators and historians from more than 30 countries have actively
          contributed to the present on-line tool. Recently also, educators from
          India, Middle East, North Africa and the United States have expressed
          an interest in contributing to the website. Historiana will continue
          to grow as more and more contributors upload suitable material.
        </p>
        <hr />
        <h5>The Team</h5>
        <p>
          <strong>
            <a href="http://euroclio.eu" target="_blank">
              <i class="fa fa-external-link" /> EuroClio
            </a>
            – European Association of History Educators
          </strong>, a community of educators who are committed to continuously improve
          the way history is being taught. EuroClio is run by a professional
          secretariat, which is supervised by a democratically elected board.
          EUROCLIO wants to enable and inspire educators to help their students
          become active and critical citizens through meaningful and inclusive
          education. EUROCLIO has over twenty years’ experience in organising
          trainings, managing (inter)-national projects and the collaborative
          design and testing of educational resources. EUROCLIO is coordinating
          the overall development of Historiana.
        </p>
        <p>
          <strong>
            <a href="https://webtic.eu" target="_blank">
              <i class="fa fa-external-link" /> Webtic
            </a>,
          </strong>is a company with a long history rooted in hacking and a great
          passion for design. Ever since the early start of the worldwide-web
          Webtic is building smart solutions based on open source server
          software and web technologies. A signature feature of Webtic’s work, is
          that they make use of the latest developments which enables them to
          see possibilities that haven’t been discovered by others.
        </p>
        <p>
          <strong>
            <a href="https://openminds.nl" target="_blank">
              <i class="fa fa-external-link" /> OpenMinds
            </a>,
          </strong>is the research and brainstorm department of Webtic.
        </p>
       
      </div>
      <div class="col-3" id="contact">
        <h1>Contact</h1>
        <p>
          The research of Historiana is coordinated by Webtic - OpenMInds and EuroClio – European
          Association of History Educators.
        </p>
        <hr />
        <p>
          For any question, remarks or suggestions related to Historiana please
          contact:
        </p>
        <p>
          <strong>Nique Sanders</strong>, UX researcher
        <br />
          <a href="mailto:nique@openminds.nl">nique@openminds.nl</a>
        </p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "About"
};
</script>
<style scoped>
#about,
#contact {
  margin-top: 2em;
  margin-bottom: 2em;
}
#about h1,
#contact h1 {
  margin-bottom: 0.5em;
  border-bottom: 1px solid;
}
#contact {
  background-color: #f5f5f5;
  padding: 0 20px;
}
a {
  color: #00aaee;
}
hr {
  border: 0;
  border-bottom: 1px dotted #959595;
  margin: 40px 0;
}
h5 {
  line-height: 1.5em;
}
</style>
